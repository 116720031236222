<template>
  <section class="bg-grey-light font-sans">
    <div class="container w-full md:max-w-3xl mx-auto py-20">
      <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal" style="font-family: Georgia, serif">
        <div class="font-sans">
          <h1 class="font-bold font-sans break-normal text-black-1 pt-6 pb-2 text-3xl md:text-4xl text-center"><a href="https://isilive.ca/" target="_blank">www.iSiLIVE.ca</a> Privacy Policy</h1>
          <p class="mt-4 text-center">Type of website: News or Media</p>
          <p class="text-center">Effective date: 1st day of February, 2023</p>
        </div>
        <p class="py-6 leading-1.5 tracking-normal">
          <a href="https://isilive.ca/" target="_blank">www.iSiLIVE.ca</a> (the "Site") is owned and operated by iSiLIVE. iSiLIVE is the data controller and can be contacted at:
        </p>
        <p>(888) 683-5727</p>
        <p>support@isilive.ca</p>
        <p>150 Terence Matthews Crescent</p>

        <b class="mt-6 block underline">Purpose</b>
        <p class="mb-12">The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following:</p>
        <p>1. The personal data we will collect;</p>
        <p>2. Use of collected data;</p>
        <p>3. Who has access to the data collected;</p>
        <p>4. The rights of Site users; and</p>
        <p>5. The Site's cookie policy.</p>
        <p class="mb-4 mt-12">This Privacy Policy applies in addition to the terms and conditions of our Site.</p>
        <b class="mt-6 block underline">GDPR</b>
        <p>
          For users in the European Union, we adhere to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, known as the General Data Protection Regulation
          (the "GDPR"). For users in the United Kingdom, we adhere to the GDPR as enshrined in the Data Protection Act 2018.
        </p>
        <p class="my-8">
          We have not appointed a Data Protection Officer as we do not fall within the categories of controllers and processors required to appoint a Data Protection Officer under Article 37 of the
          GDPR.
        </p>
        <b class="mt-6 block underline">Consent</b>
        <p>By using our Site users agree that they consent to:</p>
        <p class="my-8">1. The conditions set out in this Privacy Policy.</p>
        <p>
          When the legal basis for us processing your personal data is that you have provided your consent to that processing, you may withdraw your consent at any time. If you withdraw your consent,
          it will not make processing which we completed before you withdrew your consent unlawful.
        </p>
        <p class="my-8">You can withdraw your consent by: Contacting the Data Protection Officer.</p>
        <b class="mt-6 block underline">Legal Basis for Processing</b>
        <p>We collect and process personal data about users in the EU only when we have a legal basis for doing so under Article 6 of the GDPR.</p>
        <p class="mb-12 mt-8">We rely on the following legal basis to collect and process the personal data of users in the EU:</p>

        <p class="my-8">1. Users have provided their consent to the processing of their data for one or more specific purposes.</p>
        <b class="mt-6 block underline">Personal Data We Collect</b>
        <p>We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without notifying you first.</p>
        <p class="mt-8 underline">Data Collected in a Non-Automatic Way</p>
        <p>We may also collect the following data when you perform certain functions on our Site:</p>
        <p class="my-8">1. Email address.</p>
        <p>This data may be collected using the following methods:</p>
        <p class="my-8">1. User opt-in account creation.</p>
        <b class="mt-6 block underline">How We Use Personal Data</b>
        <p>
          Data collected on our Site will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Site. We will not use your data beyond what we
          disclose in this Privacy Policy.
        </p>
        <p class="my-8">The data we collect when the user performs certain functions may be used for the following purposes:</p>
        <p>1. Authentication to view protected content; and</p>
        <p class="mt-4">2. Identify a user in a public chat room.</p>
        <b class="mt-6 block underline">Who We Share Personal Data With</b>
        <p class="underline">Employees</p>
        <p>We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.</p>
        <p class="underline mt-8">Other Disclosures</p>
        <p class="mb-12">We will not sell or share your data with other third parties, except in the following cases:</p>
        <p class="mb-2">1. If the law requires it;</p>
        <p class="mb-2">2. If it is required for any legal proceeding;</p>
        <p class="mb-2">3. To prove or protect our legal rights; and</p>
        <p>4. To buyers or potential buyers of this company in the event that we seek to sell the company.</p>
        <p class="my-8">If you follow hyperlinks from our Site to another Site, please note that we are not responsible for and have no control over their privacy policies and practices.</p>
        <b class="mt-6 block underline">How Long We Store Personal Data</b>
        <p>User data will be stored until the purpose the data was collected for has been achieved.</p>
        <p class="my-8">You will be notified if your data is kept for longer than this period.</p>
        <b class="mt-6 block underline">How We Protect Your Personal Data</b>
        <p>
          In order to protect your security, we use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only accessible to our employees.
          Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination.
        </p>
        <p class="my-8">
          While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a whole can be insecure at
          times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.
        </p>
        <b class="mt-6 block underline">Your Rights as a User</b>
        <p>Under the GDPR, you have the following rights:</p>
        <p class="mb-2">1. Right to be informed;</p>
        <p class="mb-2">2. Right of access;</p>
        <p class="mb-2">3. Right to rectification;</p>
        <p class="mb-2">4. Right to erasure;</p>
        <p class="mb-2">5. Right to restrict processing;</p>
        <p class="mb-2">6. Right to data portability; and</p>
        <p class="mb-2">7. Right to object.</p>
        <b class="mt-6 block underline">Children</b>
        <p>
          We do not knowingly collect or use personal data from children under 16 years of age. If we learn that we have collected personal data from a child under 16 years of age, the personal data
          will be deleted as soon as possible. If a child under 16 years of age has provided us with personal data their parent or guardian may contact our privacy officer.
        </p>
        <b class="mt-6 block underline">How to Access, Modify, Delete, or Challenge the Data Collected</b>
        <p>
          If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, if
          you would like your data to be deleted or modified in any way, or if you would like to exercise any of your other rights under the GDPR, please contact our privacy officer here:
        </p>
        <p class="mt-4 mb-24">support@iSiLIVE.ca</p>
        <b class="block underline">How to Opt-Out of Data Collection, Use or Disclosure</b>
        <p>
          In addition to the method(s) described in the How to Access, Modify, Delete, or Challenge the Data Collected section, we provide the following specific opt-out methods for the forms of
          collection, use, or disclosure of your personal data specified below:
        </p>
        <p class="my-6">1. You can opt-out of the use of your personal data for marketing emails. You can opt-out by clicking "unsubscribe" on the bottom of any marketing email.</p>
        <b class="block underline">Cookie Policy</b>
        <p>
          A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie
          is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.
        </p>
        <p class="my-8">We use the following types of cookies on our Site:</p>
        <p>1. <span class="underline">Functional cookies</span></p>
        <p class="ml-4">Functional cookies are used to remember the selections you make on our Site so that your selections are saved for your next visits.</p>
        <b class="block underline mt-6">Modifications</b>
        <p>
          This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy
          we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If
          necessary, we may notify users by email of changes to this Privacy Policy.
        </p>
        <b class="block underline mt-6">Complaints</b>
        <p>
          If you have any complaints about how we process your personal data, please contact us through the contact methods listed in the Contact Information section so that we can, where possible,
          resolve the issue. If you feel we have not addressed your concern in a satisfactory manner you may contact a supervisory authority. You also have the right to directly make a complaint to a
          supervisory authority.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyNotice',
};
</script>
